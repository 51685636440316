import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: process.env.GATSBY_APP_SENTRY_DSN,
  tracesSampleRate: 1,
  browserTracingOptions: {
    traceXHR: false,
  },
  environment: process.env.GATSBY_APP_ENV_TYPE,
  release: process.env.GATSBY_APP_VERSION,
});
