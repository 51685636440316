
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect,useState } from "react"
import Cookies from 'universal-cookie';
import { t } from 'i18next';
import UserContext from '../../context/UserContext';
import impersonateService from '../../data/impersonateService';
import { cookiesOption } from '../../utility/Utils';

const ImpersonateBanner = () => {
  const [token, setToken] = useState(null);
  const cookieStore = new Cookies();
  const {setIsImpersonate} =  useContext(UserContext)
 
  useEffect(() => {
    const tokenFromCookies = cookieStore.get('jwt');
    if (tokenFromCookies) {
      const decodeJwt = jwtDecode(tokenFromCookies); 
      setToken(decodeJwt)
      if(token?.impersonated_by){
        setIsImpersonate(true) 
      } else {setIsImpersonate(false)}}
  
  }, [token?.impersonated_by]);

  const stopImpersonate = async () => {
    const response =  await impersonateService.delete(`/impersonate`)
    const jwt = await response.data.access_token;
    cookieStore.set('jwt',jwt, {domain:process.env.GATSBY_DOMAIN, path:"/"})
    location.href = process.env.GATSBY_PORTAIL_URL
  }


  const dateTokenFormated = new Date(token?.iat * 1000);
  const displayHourImpersonate = `${dayjs(dateTokenFormated).format(
    'HH'
  )}h${dayjs(dateTokenFormated).format('mm')}`;

  return ( 
    <>
      {token?.impersonated_by && (
        <div className='w-full px-8 lg:px-60 py-1  h-[38px]  bg-[#333333]  z-50 sticky top-0 text-white mx-auto text-sm font-lato'>
          <div className=' h-full w-full flex lg:justify-between items-center mx-auto pl-14 lg:pl-0 '>
            <div className='flex items-center gap-4'>
              <div className='bg-green-500 h-2 w-2 rounded-full ' />
              <p>
                {t('connectedAt')} {displayHourImpersonate}
              </p>
            </div>
            <div className='flex items-center'>
              <p className='inline'>{t('stopImpersonate')}</p>
              <button
                onClick={stopImpersonate}
                className='ml-4 h-7 text-xs hover:opacity-90 bg-red-600 px-4 rounded-md'
              >
                {t('exit')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ImpersonateBanner;