import axios from 'axios';
import Cookies from 'universal-cookie';

const jwt = new Cookies().get('jwt');

const impersonateService = axios.create({
  baseURL: process.env.GATSBY_PORTAIL_API,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    InternalKey: process.env.GATSBY_INTERNAL_KEY,
    Authorization: `bearer ${jwt} `,
  },
});

export default impersonateService;
