import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import logo from '@assets/logos/logo-header-xefi.png';

import ArrowSidebar from '@assets/svg/arrow-sidebar-logo.inline.svg';

import { classNames } from '@utility/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const SidebarModal = ({ sidebarOpen, setSidebarOpen, navigation }) => {
  const { t } = useTranslation();
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-[120] md:hidden'
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
        </Transition.Child>

        <div className='fixed inset-0 z-40 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-secondarylight'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 pt-2 z-50'>
                  <button
                    type='button'
                    className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none cursor-pointer'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XMarkIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className='h-0 flex-1 overflow-y-auto pt-5 pb-4 relative'>
                <div className='flex items-center justify-center p-4 mb-3'>
                  <a
                    href={`${process.env.GATSBY_PORTAIL_URL}`}
                    rel='noreferrer'
                  >
                    <img src={logo} alt='logo dailyApps' />
                  </a>
                </div>
                <div className='flex items-center px-4 mb-5'>
                  <button
                    className='flex content-between px-1 items-center rounded-3xl bg-white w-full h-[56px] z-40'
                    onClick={() => {
                      navigate('/request');
                      setSidebarOpen(false);
                    }}
                  >
                    <div className='w-12 h-12  bg-primary1 rounded-full text-white text-center flex content-center items-center'>
                      <FontAwesomeIcon
                        icon={faPlus}
                        size='2xl'
                        className='w-full'
                      />
                    </div>
                    <span className='w-3/4 text-danger-600'>
                      {t('newRequest')}
                    </span>
                  </button>
                </div>
                <nav className='mt-5 space-y-1 px-2 relative'>
                  {navigation?.map((item, index) => {
                    return item.hasAccess ? (
                      <Link
                        to={item.navigate}
                        key={index}
                        className={classNames(
                          item.current
                            ? 'bg-white shadow-lg sidebar-edging'
                            : 'text-white  hover:text-gray-400 transition-colors duration-150 ',
                          'flex relative justify-between items-center py-3 px-4 cursor-pointer menu-transition'
                        )}
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={item.icon && item.icon}
                            size='lg'
                            className={classNames(
                              item.current ? 'text-primary1' : 'text-gray-300',
                              'mr-5 line-transition w-5 transition-all'
                            )}
                          />
                          <span className='text-[15.4px] truncate title font-normal pb-[2px] leading-5 '>
                            {t(item.name)}
                          </span>
                        </div>
                      </Link>
                    ) : null;
                  })}
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className='w-14 flex-shrink-0'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SidebarModal;
