import axios from 'axios';

import { requestInterceptor, responseInterceptor } from './interceptorService';

// ** Axios built in tool for conges backend

const congesService = axios.create({
  baseURL: process.env.GATSBY_APP_CONGES_BASE_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

//Intercept the request
congesService.interceptors.request.use(requestInterceptor);

//Intercept the response
congesService.interceptors.response.use(
  (response) => response,
  (error) => responseInterceptor(error)
);

export default congesService;
