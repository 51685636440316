import React, { createContext, useState } from 'react';
import congesService from '@data/congesService';
const defaultContext = {
  setUser: () => {},
  notifications: [],
  setNotifications: () => {},
  currentUser: {},
  setCurrentUser: () => {},
  isImpersonate: false,
  setIsImpersonate: () => {},
  actionsDirector: () => {},
  actionName: '',
  setActionName: (value) => {},
  isDirector: false,
};

const UserContext = createContext(defaultContext);

export const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [isImpersonate, setIsImpersonate] = useState(false);
  const [actionName, setActionName] = useState(null);

  const actionsDirector = async (selectedUsers, action) => {
    if (!['attach', 'detach'].includes(action)) return;
    if (!Array.isArray(selectedUsers) || selectedUsers.length < 1) return;

    try {
      const dataIds = selectedUsers.map(({ id }) => id);
      await congesService.post(`/v1/director/${action}`, {
        resources: [...dataIds],
      });
      setActionName(action);
    } catch (error) {
      console.error(error);
    }
  };

  const isDirector = currentUser?.profile?.label === 'DIRECTOR';
  return (
    <UserContext.Provider
      value={{
        setCurrentUser,
        currentUser,
        isImpersonate,
        setIsImpersonate,
        actionsDirector,
        actionName,
        setActionName,
        isDirector,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
