import axios from 'axios';

// ** Axios built in tool for authentication (authprovider)

const authProviderService = axios.create({
  baseURL: process.env.GATSBY_PORTAIL_API,
  timeout: 90000,
  headers: {
    fromApp: process.env.GATSBY_APP_AUTH_UUID,
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export default authProviderService;
