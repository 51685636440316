import {
  faHouse,
  faSuitcase,
  faCalendarAlt,
  faPeopleGroup,
  faCheck,
  faCircleInfo,
  faQrcode,
  faPaperPlane,
  faGear,
  faMobile,
} from '@fortawesome/pro-regular-svg-icons';

export const initialNavigation = [
  {
    name: 'dashboard',
    current: false,
    navigate: '/',
    icon: faHouse,
  },

  {
    name: 'myLeaves',
    icon: faSuitcase,
    current: false,
    navigate: '/leaves/',
  },
  {
    name: 'planning',
    icon: faCalendarAlt,
    current: false,
    navigate: '/planning/',
  },
  {
    name: 'teams',
    icon: faPeopleGroup,
    current: false,
    navigate: '/teams/',
  },
  {
    name: 'validation',
    icon: faCheck,
    current: false,
    navigate: '/validation/',
  },
  {
    name: 'sendToAccounting',
    icon: faPaperPlane,
    current: false,
    navigate: '/sendToAccounting/',
  },
  {
    name: 'documentation',
    icon: faCircleInfo,
    current: false,
    navigate: '/documentation/',
  },
  {
    name: 'setting',
    icon: faGear,
    current: false,
    navigate: '/setting/',
  },
  {
    name: 'mobileApp',
    icon: faMobile,
    current: false,
    navigate: '/download/',
  },
];
