import { checkJWT } from '@utility/Utils';
import authProviderService from './authProviderService';

import Cookies from 'universal-cookie';
import { cookiesOption } from '../utility/Utils';

export async function requestInterceptor(config) {
  let jwt = '';
  const cookies = new Cookies();
  jwt = cookies.get('jwt');

  if (jwt) {
    const jwtStatus = checkJWT(jwt);
    if (jwtStatus === 'refreshable') {
      try {
        const response = await authProviderService.post(
          '/auth/refresh',
          {},
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        jwt = response.data.access_token;
        cookies.set('jwt', jwt, cookiesOption);
      } catch (error) {
        cookies.remove('jwt', cookiesOption);
        window.location.href = `${process.env.GATSBY_PORTAIL_URL}/auth`;
      }
    }
    config.headers.Authorization = `${jwt}`;
  }
  return config;
}

// ** Intercept the response
export function responseInterceptor(error) {
  if (error?.response?.status === 401) {
    const cookies = new Cookies();
    if (process.env.ENV_TYPE === 'LOCAL') {
      cookies.remove('jwt');
    } else {
      cookies.remove('jwt', cookiesOption);
    }
    window.location.href = `${process.env.GATSBY_PORTAIL_URL}/auth`;
  }
  return Promise.reject(error);
}
