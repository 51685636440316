import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const NotificationBadge = ({ numberOfNotification }) => {
  return (
    <div>
      <div className='relative font-montserrat'>
        <span className='notification'>{numberOfNotification}</span>
      </div>
    </div>
  );
};

export default NotificationBadge;
