import React, { Fragment } from 'react';
import logo from '@assets/logos/logo-header-xefi.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPersonWalkingArrowRight,
  faPowerOff,
  faUser,
} from '@fortawesome/pro-light-svg-icons';

import { useTranslation } from 'react-i18next';

import { Menu, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';

import { classNames, getFullname, cookiesOption } from '@utility/Utils';
import { navigate } from 'gatsby';

import Cookies from 'universal-cookie';

import authProvider from '@data/authProviderService';

const Header = ({ setSidebarOpen, currentUser }) => {
  const { t, i18n } = useTranslation();

  const handleLogout = async () => {
    const cookies = new Cookies();
    const jwt = cookies.get('jwt', cookiesOption);
    try {
      await authProvider.post(
        '/auth/logout',
        {},
        {
          headers: {
            Authorization: `bearer ${jwt}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
      ToastCustom.error(t('serverError'));
    } finally {
      cookies.remove('jwt', cookiesOption);
      window.location.replace(`${process.env.GATSBY_PORTAIL_URL}/auth`);
    }
  };

  return (
    <header className='h-[65px] box-shadow-header flex items-center'>
      <div className='min-w-[270px] border-r-2 border-solid border-secondary-300 flex justify-center items-center cursor-pointer'>
        <button
          type='button'
          className='px-4 text-gray-500 focus:outline-none md:hidden'
          onClick={() => setSidebarOpen(true)}
        >
          <span className='sr-only'>{t('openSidebar')}</span>
          <Bars3BottomLeftIcon className='h-6 w-6' aria-hidden='true' />
        </button>
        <button className='cursor-pointer'>
          <a href={`${process.env.GATSBY_PORTAIL_URL}`} rel='noreferrer'>
            <img src={logo} alt='logo dailyApps' />
          </a>
        </button>
      </div>
      <div className='flex justify-between items-center w-full mx-4'>
        <h1 className='text-2xl font-semibold pl-2 cursor-default select-none'>
          {t('leaves')}
        </h1>
        <nav>
          <ul className='flex items-center'>
            <li>
              <Menu as='div' className='relative inline-block text-left'>
                <Menu.Button className='flex items-center gap-1 w-full justify-center rounded-md px-4 py-2 text-sm font-medium hover:bg-gray-50'>
                  <FontAwesomeIcon icon={faUser} size='lg' className='mr-3' />
                  <span className='hidden md:flex '>
                    {getFullname(currentUser)}
                  </span>
                  <ChevronDownIcon
                    className='-mr-1 ml-2 h-5 w-5'
                    aria-hidden='true'
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-[0px] top-[50px] z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                    <div className='py-1'>
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={() =>
                              navigate(`/account/${currentUser?.id}/`)
                            }
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full px-4 py-2 text-left text-sm hover:bg-lightgrey-100 cursor-pointer'
                            )}
                          >
                            <FontAwesomeIcon icon={faUser} className='mr-3' />
                            {t('myAccount')}
                          </span>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={() => {
                              window.location.href =
                                process.env.GATSBY_PORTAIL_URL;
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full px-4 py-2 text-left text-sm hover:bg-lightgrey-100 cursor-pointer'
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faPersonWalkingArrowRight}
                              className='mr-2'
                            />
                            {t('returnHome')}
                          </span>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={handleLogout}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full px-4 py-2 text-left text-sm hover:bg-lightgrey-100 cursor-pointer'
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faPowerOff}
                              className='mr-3'
                            />
                            {t('logout')}
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
