import React, { useContext } from 'react';

import { Link, navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { classNames } from '@utility/Utils';

import ArrowSidebar from '@assets/svg/arrow-sidebar-logo.inline.svg';
import NotificationContext from '@context/NotificationContext';
import NotificationBadge from '@components/UI/NotificationBadge';

const Sidebar = ({ navigation }) => {
  const { t } = useTranslation();

  const {
    getValidationToProcessNotif,
    getValidationToCancelNotif,
    getAccountingNotif,
  } = useContext(NotificationContext);

  const validationNotif =
    getValidationToProcessNotif + getValidationToCancelNotif;

  return (
    <div className='flex min-h-0 flex-1 flex-col bg-secondarylight mt-[65px] '>
      <div className='flex flex-1 flex-col pt-5 pb-4 relative '>
        <div className='overflow-hidden'>
          <div className='absolute right-[-100px] bottom-[-100px] z-10'>
            <ArrowSidebar />
          </div>
        </div>
        <div className='flex items-center px-4 mb-5'>
          <button
            className='flex content-between px-1 items-center rounded-full bg-white w-full h-[56px] z-40'
            onClick={() => {
              navigate('/request');
            }}
          >
            <div className='w-12 h-12  bg-primary1 rounded-full text-white text-center flex content-center items-center'>
              <FontAwesomeIcon icon={faPlus} size='2xl' className='w-full' />
            </div>
            <span className='w-3/4 text-primary1 font-montserrat '>
              {t('newRequest')}
            </span>
          </button>
        </div>
        <nav className='mt-5 flex-1 space-y-1 relative z-20'>
          {navigation?.map((item, index) => {
            return item.hasAccess ? (
              <Link
                to={item.navigate}
                key={index}
                className={classNames(
                  item.current
                    ? 'bg-white shadow-lg sidebar-edging'
                    : 'text-white  hover:text-gray-400 transition-colors duration-150 ',
                  'flex relative justify-between items-center py-3 px-4 cursor-pointer menu-transition'
                )}
              >
                <div>
                  <FontAwesomeIcon
                    icon={item.icon && item.icon}
                    size='lg'
                    className={classNames(
                      item.current ? 'text-primary1' : 'text-gray-300',
                      'mr-5 line-transition w-5 transition-all'
                    )}
                  />
                  <span className='text-[15.4px] truncate title font-normal pb-[2px] leading-5 '>
                    {t(item.name)}
                  </span>
                </div>
                {item.name === 'validation'
                  ? validationNotif > 0 && (
                      <NotificationBadge
                        numberOfNotification={
                          validationNotif > 9 ? '9+' : validationNotif
                        }
                      />
                    )
                  : item.name === 'sendToAccounting'
                  ? getAccountingNotif > 0 && (
                      <NotificationBadge
                        numberOfNotification={
                          getAccountingNotif > 9 ? '9+' : getAccountingNotif
                        }
                      />
                    )
                  : null}
              </Link>
            ) : null;
          })}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
