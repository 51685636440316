// ** Imports
import React from 'react';

import '@src/styles/reset.scss';
import '@src/styles/global.scss';
import '@src/styles/mui.scss';
import '@src/styles/animations.scss';

//Setry

import '@src/configs/sentry.config.js';
// Configs
import '@src/configs/i18n';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { NotificationContextProvider } from '@context/NotificationContext';
import { UserContextProvider } from './src/context/UserContext';

// ** wrapRootElement is the first thing done before the page is rendered
export const wrapRootElement = ({ element }) => {
  //MUI X PRO
  LicenseInfo.setLicenseKey(process.env.GATSBY_APP_MUIKEY);

  //Sentry
  return (
    <>
      <UserContextProvider>
        <title>DAILY APPS CONGES</title>
        <NotificationContextProvider>{element}</NotificationContextProvider>
      </UserContextProvider>
    </>
  );
};
