import React, { createContext, useState } from 'react';

import congesService from '@data/congesService';

const defaultContext = {
  setUser: () => {},
  notifications: [],
  setNotifications: () => {},
  currentUser: {},
  setCurrentUser: () => {},
};

const NotificationContext = createContext(defaultContext);

export const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([
    {
      name: 'validationToProcess',
      total: 0,
    },
    {
      name: 'validationToCancel',
      total: 0,
    },
    {
      name: 'accountingToProcess',
      total: 0,
    },
  ]);

  const getValidationCount = async (user) => {
    const response = await congesService.post('/v1/leaves/search?limit=1', {
      scopes: [
        {
          name: 'waitingForUserValidation',
          parameters: [user?.id],
        },
      ],
    });
    setNotifications((prevState) => {
      return prevState.map((notif) => {
        if (notif.name === 'validationToProcess') {
          notif.total = response.data.meta.total;
        }
        return notif;
      });
    });
    const responseCancel = await congesService.post(
      '/v1/leaves/search?limit=1',
      {
        scopes: [{name: 'waitingForUserCancelation'}]
      }
    );
    setNotifications((prevState) => {
      return prevState.map((notif) => {
        if (notif.name === 'validationToCancel') {
          notif.total = responseCancel.data.meta.total;
        }
        return notif;
      });
    });

    const responseAccountingToProcess = await congesService.post(
      '/v1/leaves/search?limit=1',
      {
        filters: [
          {
            field: 'status.tag',
            operator: '=',
            value: 'VALIDATED',
          },
        ],
      }
    );
    setNotifications((prevState) => {
      return prevState.map((notif) => {
        if (notif.name === 'accountingToProcess') {
          notif.total = responseAccountingToProcess.data.meta.total;
        }
        return notif;
      });
    });
  };
  const decrementNotifications = (name, number) => {
    //return notification with the new total
    const newNotifications = notifications.map((notif) => {
      if (notif.name === name) {
        notif.total = number;
      }
      return notif;
    });
    setNotifications(newNotifications);
  };

  const getValidationToProcessNotif = notifications.find(
    (notif) => notif.name === 'validationToProcess'
  ).total;

  const getValidationToCancelNotif = notifications.find(
    (notif) => notif.name === 'validationToCancel'
  ).total;

  const getAccountingNotif = notifications.find(
    (notif) => notif.name === 'accountingToProcess'
  ).total;

  return (
    <NotificationContext.Provider
      value={{
        decrementNotifications,
        getValidationToProcessNotif,
        getValidationToCancelNotif,
        getAccountingNotif,
        getValidationCount,
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
