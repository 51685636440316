import axios from 'axios';

// ** Axios built in tool for translation

export default axios.create({
  baseURL: process.env.GATSBY_APP_TRANSLATION_BASE_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json; charset=utf-8',
  },
  params: {
    appUuid: process.env.GATSBY_APP_APP_UUID,
  },
});
